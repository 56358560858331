
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   overflow-x: hidden;

  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

.scroll-container {
  overflow-x: auto; 
  width: 100%; 
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  cursor: grab;  /* Hand symbol */
}

.scroll-container::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

.scroll-container:active {
  cursor: grabbing;  /* Hand symbol when dragging */
}
